/**
 * 是否是安卓
 *
 * @export
 * @returns
 */
export function isAndroid(ua?: string): boolean {
	let _ua: string = ua || (typeof window === 'undefined' ? '' : window.navigator.userAgent || '');

	return _ua.indexOf('Android') > -1 || _ua.indexOf('Linux') > -1;
}

/**
 * 是否是ios
 *
 * @export
 * @returns
 */
export function isIOS(ua?: string): boolean {
	let _ua: string = ua || (typeof window === 'undefined' ? '' : window.navigator.userAgent || '');

	return !!_ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
}

/**
 * 是否是微信
 *
 * @export
 * @returns
 */
export function isWeixin(ua?: string): boolean {
	let _ua: string = ua || (typeof window === 'undefined' ? '' : window.navigator.userAgent || '');

	return /MicroMessenger/i.test(_ua);
}

/**
 * 是否是微博
 *
 * @export
 * @returns
 */
export function isWeibo(ua?: string): boolean {
	let _ua: string = ua || (typeof window === 'undefined' ? '' : window.navigator.userAgent || '');

	return /WeiBo/i.test(_ua);
}

/**
 * 是否是pc端
 *
 * @export
 * @returns
 */
export function isPc(ua?: string): boolean {
	return !isAndroid(ua) && !isIOS(ua);
}

/**
 * 是否荷小鱼app
 *
 * @export
 * @returns
 */
export function isHxyApp(ua?: string): boolean {
	let _ua: string = ua || (typeof window === 'undefined' ? '' : window.navigator.userAgent || '');

	return /hexiaoyu/i.test(_ua);
}

/**
 * 是否千聊
 *
 * @export
 * @returns
 */
export function isQlchat(ua?: string): boolean {
	let _ua: string = ua || (typeof window === 'undefined' ? '' : window.navigator.userAgent || '');

	return /qlchat/i.test(_ua);
}

/**
 * 是否千聊版本
 *
 * @export
 * @returns
 */
export function getQlchatVersion(): any {
	let ua: any = typeof window === 'undefined' ? '' : window.navigator.userAgent || '';

	let qlver: any = ua.toLowerCase().match(/qlchat[a-zA-Z]*?\/([\d.]+)/);

	if (qlver && qlver.length) {
		// tslint:disable-next-line:radix
		return parseInt(qlver[1]);
	}

	return;
}
